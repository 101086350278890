<template>
    <div>
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flexk a-i-center mb20">
                        <div class="title-internal-block">Litsenziya tartibi: {{ procedure_name }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-tabs tab-position="left"  v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="Tibbiy xodimlar" name="doctors">
                <Employees :activeName="activeName" @get-name="setName"></Employees>
            </el-tab-pane>
            <el-tab-pane label="Tibbiy Texnikalar" name="techniques">
                <Techniques :activeName="activeName" @get-name="setName"></Techniques>
            </el-tab-pane>
            <el-tab-pane label="Tibbiy uskunalar" name="equipments">
                <Institutions :activeName="activeName" @get-name="setName"></Institutions>
            </el-tab-pane>
        </el-tabs>  
    </div>
</template>
<script>
import Employees from "./components/employees.vue";
import Techniques from "./components/techniques.vue";
import Institutions from "./components/institutions.vue";
export default {
    name: "licenseRequirement",
    components: {
        Employees,
        Techniques,
        Institutions
    },
    data() {
        return {
            activeName: 'doctors',
            procedure_name: ''
        };
    },
    methods: {
        handleClick(tab) {
            this.activeName = tab.name;
        },
        setName(item){
            if (item) {
               this.procedure_name = item.name;
            }
        }
    }
};
</script>

