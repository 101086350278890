<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Tibbiy Texnikalar talablari</div>
                </div>
            </el-col>
            <el-col :span="6" class="filter_table">
                <el-button @click="drawerCreate = true">Arizalar qo'shish</el-button>
            </el-col>
        </el-row>
        <el-collapse accordion class="licenseRequiments-collapse" v-loading="loadingData">
            <el-collapse-item v-for="(item, index) in list" :key="index" :name="index">
                <template slot="title">
                    <el-row :gutter="20">
                        <el-col :span="16">
                            <div class="text-t-e">
                                <p>Texnika turi: {{item.technique_type ? item.technique_type.name : ''}}</p>
                            </div>
                        </el-col>
                        <!-- <el-col :span="5">
                            <div class="text-t-e">
                                <p>Xolat</p>
                            </div>
                        </el-col> -->
                        <el-col :span="8">
                            <div class="text-t-e">
                                <p>Boshqarmoq</p>
                            </div>
                        </el-col>
                    </el-row> 
                </template>
                <div>
                    <div class="itme-licenseRequiments-e">
                        <el-row class="bor-bottom-d-s" :gutter="20" v-for="data in item.items" :key="'items-'+ data.id">
                            <el-col :span="16">
                                <div class="text-t-e">
                                    <p v-if="data.name == 'technique_type'">Soni: {{ data.interval ? (data.interval.li_until + ' ta') : ''}}</p>
                                    <p v-else-if="data.name == 'series_and_number'">Seriya va raqami</p>
                                    <p v-else-if="data.name == 'passport'">PASSPORT
                                        <!-- <i v-if="data.boolean.li_type" class="success-icon el-icon-circle-check"></i> 
                                        <i v-else class="danger-icon el-icon-circle-close"></i>  -->
                                    </p>
                                    <p v-else-if="data.name == 'manual'">Qo'llanmasi
                                        <!-- <i v-if="data.boolean.li_type" class="success-icon el-icon-circle-check"></i> 
                                        <i v-else class="danger-icon el-icon-circle-close"></i>  -->
                                    </p>
                                    <p v-else-if="data.name == 'document'">Guvohnomasi
                                        <!-- <i v-if="data.boolean.li_type" class="success-icon el-icon-circle-check"></i> 
                                        <i v-else class="danger-icon el-icon-circle-close"></i>  -->
                                    </p>
                                    <p v-else-if="data.name == 'document_number'">Guvohnomasi va raqami</p>
                                    <p v-else-if="data.name == 'certificate'">Sertifikati
                                        <!-- <i v-if="data.boolean.li_type" class="success-icon el-icon-circle-check"></i> 
                                        <i v-else class="danger-icon el-icon-circle-close"></i>  -->
                                    </p>
                                    <p v-else-if="data.name == 'certificate_number'">Sertifikati va raqami</p>
                                    <p v-else-if="data.name == 'property_ownership'">Mulk huquqi: 
                                        {{data.li_select ? (data.li_select.property_ownership ?  data.li_select.property_ownership.name : '') : ''}}
                                    </p>
                                    <p v-else-if="data.name == 'condition'">Holati: 
                                        {{data.li_select ? (data.li_select.condition ?  data.li_select.condition.condition : '') : ''}}
                                    </p>
                                    <p v-else-if="data.name == 'date_of_manufacture'">Ishlab chiqarilgan sanasi
                                        <!-- <i v-if="data.boolean.li_type" class="success-icon el-icon-circle-check"></i> 
                                        <i v-else class="danger-icon el-icon-circle-close"></i>  -->
                                    </p>
                                </div>
                            </el-col>
                            <!-- <el-col :span="5">
                                <div class="text-t-e" v-if="data.li_condition != null">
                                   <div :class="[data.li_condition ? 'success-bg' : 'danger-bg']"> {{ data.li_condition ? 'Shart' : 'Shart emas' }} </div>
                                </div>
                                <div class="text-t-e" v-else>
                                   <div class="'ligth-bg'"> </div>
                                </div>
                            </el-col> -->
                            <el-col :span="8">
                                <div class="text-t-e" v-if="data.li_status != null">
                                   <div :class="[data.li_status ? 'success-bg' : 'danger-bg']"> {{data.li_status ? 'Ha' : "Yo'q"}} </div>
                                </div>
                                <div class="text-t-e" v-else>
                                   <div class="'ligth-bg'"> </div>
                                </div>
                            </el-col>
                        </el-row> 
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
        <div class="my-pagination">
            <crm-pagination
                @c-change="updatePagination"
                :pagination="pagination"
            ></crm-pagination>
        </div>

        <el-drawer
            :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')">
            <div>
                <techniquesCreate
                ref="drawerCreateChild"
                drawer="drawerCreate">
                </techniquesCreate>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import techniquesCreate from "./components/techniques-create";
export default {
    props: {
        activeName: {
            default: null,
            type: String
        }
    },
    components: {
        techniquesCreate,
    },
    computed: {
        ...mapGetters({
            list: "licenseRequirementTechniques/list",
            columns: "licenseRequirementTechniques/columns",
            pagination: "licenseRequirementTechniques/pagination",
            filter: "licenseRequirementTechniques/filter",
            sort: "licenseRequirementTechniques/sort"
        }),
    },
    watch:{
        $props: {
            handler: function(){
                if (this.activeName == 'techniques') {
                    this.fetchData();
                }else{
                    this.empty();
                }
            },
            deep: true
        },
       'pagination.page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) {
                    this.fetchData();
                }
            },
            deep: true
        },
        'pagination.per_page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) { 
                    this.fetchData();
                }
            },
            deep: true
        },
    },
    data() {
        return {
            loadingData: false,
            drawerCreate: false,
            reloadList: false
        };
    },
    methods: {
        ...mapActions({
            updateList: "licenseRequirementTechniques/index",
            setPagination: "licenseRequirementTechniques/setPagination",
            updateSort: "licenseRequirementTechniques/updateSort",
            updateFilter: "licenseRequirementTechniques/updateFilter",
            updateColumn: "licenseRequirementTechniques/updateColumn",
            updatePagination: "licenseRequirementTechniques/updatePagination",
            show: "licenseRequirementTechniques/show",
            empty: "licenseRequirementTechniques/empty",
            delete: "licenseRequirementTechniques/destroy",
            refreshData: "licenseRequirementTechniques/refreshData",
        }),
        fetchData() { 
            const query = {
                license_requirement_id: parseInt(this.$route.params.id),
                ...this.pagination,
            };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false;  
                    this.$emit('get-name', res.procedure);   
                }).catch(err => {
                    this.loadingData = false;
                });
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        drawerClosed(ref){
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
        },
        closeDrawer(drawer){
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
    }
};
</script>
