<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Tibbiy xodimlar</div>
                </div>
            </el-col>
            <el-col :span="6" class="filter_table">
                    <el-button @click="drawerCreate = true">Arizalar qo'shish</el-button>
            </el-col>
        </el-row>
        <el-collapse accordion class="licenseRequiments-collapse" v-loading="loadingData">
            <el-collapse-item v-for="(item, index) in list" :key="index" :name="index">
                <template slot="title">
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="text-t-e">
                                <p>Xodim turi:</p> {{ item.employee ? item.employee.name : '' }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="text-t-e">
                                <p>Xodim yo'nalishi:</p> {{ item.direction ? item.direction.name : '' }}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="text-t-e">
                                <p>Boshqarmoq</p>
                            </div>
                        </el-col>
                    </el-row> 
                </template>
                <div>
                    <div class="itme-licenseRequiments-e">
                        <el-row class="bor-bottom-d-s" :gutter="20" v-for="data in item.items" :key="'items-'+ data.id">
                            <el-col :span="16">
                                <div class="text-t-e">
                                    <p v-if="data.name == 'type_of_employee'">Xodimlar soni: {{data.interval ? (data.interval.li_until + ' ta') : ''}}</p>
                                    <p v-else-if="data.name == 'passport_and_series'">Pasport seriyasi va raqami</p>
                                    <p v-else-if="data.name == 'inn'">INN</p>
                                    <p v-else-if="data.name == 'region'">Viloyat</p>
                                    <p v-else-if="data.name == 'work_experience'">Ish Tajribasi: <span>
                                        {{ data.interval ? (data.interval.li_from +' yildan ' + data.interval.li_until + ' yilgacha') : '' }}</span></p>
												<p v-else-if="data.name == 'certificate'">Sertifikat ma'lumotlari</p>
												<p v-else-if="data.name == 'diploma'">Diplom ma'lumotlari</p>
                                    <p v-else-if="data.name == 'educations'">Oliy o'quv yurti  <el-button @click="ShowData(data)"  type="text"> Ko'rish <i class="el-icon-view"></i></el-button></p>
                                    <p v-else-if="data.name == 'qualification_category'">Malaka Toifasi <el-button @click="ShowData(data)" type="text"> Ko'rish <i class="el-icon-view"></i></el-button></p>
                                    <p v-else-if="data.name == 'academic_degree'">Ilmiy Darajasi <el-button @click="ShowData(data)" type="text"> Ko'rish <i class="el-icon-view"></i></el-button></p>
                                    <p v-else-if="data.name == 'steerage'"> Boshqaruvda
                                        <i v-if="data.boolean.li_type" class="success-icon el-icon-circle-check"></i> 
                                        <i v-else class="danger-icon el-icon-circle-close"></i> 
                                        <!-- (Qanday boshqaruvda ishlagan)   -->
                                    </p>
                                </div>
                            </el-col>
                            <!-- <el-col :span="5">
                                <div class="text-t-e" v-if="data.li_condition != null">
                                   <div :class="[data.li_condition ? 'success-bg' : 'danger-bg']"> {{ data.li_condition ? 'Shart' : 'Shart emas' }} </div>
                                </div>
                                <div class="text-t-e" v-else>
                                   <div class="'ligth-bg'"> </div>
                                </div>
                            </el-col> -->
                            <el-col :span="8">
                                <div class="text-t-e" v-if="data.li_status != null">
                                   <div :class="[data.li_status ? 'success-bg' : 'danger-bg']"> {{data.li_status ? 'Ha' : "Yo'q"}} </div>
                                </div>
                                <div class="text-t-e" v-else>
                                   <div class="'ligth-bg'"> </div>
                                </div>
                            </el-col>
                        </el-row>   
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
        <div class="my-pagination">
            <crm-pagination
                @c-change="updatePagination"
                :pagination="pagination"
            ></crm-pagination>
        </div>
        <el-dialog title="Shipping address" :visible.sync="dialogTableVisible" :append-to-body="true">
            <el-table :data="selectedData">
                <el-table-column :label="$t('message.nomi')" width="400">
                    <template slot-scope="item">
                        <span v-if="item.row.hasOwnProperty('education')">{{ item.row.education.name }}</span>
                        <span v-else-if="item.row.hasOwnProperty('category')">{{ item.row.category.category }}</span>
                        <span v-else-if="item.row.hasOwnProperty('academic_degree')">{{ item.row.academic_degree.name }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="Xolat">
                    <template slot-scope="item">
                        <div :class="[item.row.condition ? 'success-bg' : 'danger-bg']"> {{ item.row.condition ? 'Shart' : 'Shart emas' }} </div>
                    </template>
                </el-table-column> -->
                <el-table-column label="Boshqarmoq">
                    <template slot-scope="item">
                        <div :class="[item.row.status ? 'success-bg' : 'danger-bg']"> {{item.row.status ? 'Ha' : "Yo'q"}} </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <EmployeesCreate
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></EmployeesCreate>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import EmployeesCreate from "./components/employees-create";
export default {
    props: {
        activeName: {
            default: null,
            type: String
        }
    },
    components: {
        EmployeesCreate,
    },
    computed: {
        ...mapGetters({
            list: "licenseRequirementsAdd/list",
            columns: "licenseRequirementsAdd/columns",
            pagination: "licenseRequirementsAdd/pagination",
            filter: "licenseRequirementsAdd/filter",
            sort: "licenseRequirementsAdd/sort"
        }),
    },
    watch:{
        $props: {
            handler: function (){
                if (this.activeName == 'doctors') {
                    this.fetchData();
                }else{
                    this.empty();
                }
            },
            deep: true,
            immediate: true
        },
       'pagination.page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) {
                    this.fetchData();
                }
            },
            deep: true
        },
        'pagination.per_page': {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.fetchData)) { 
                    this.fetchData();
                }
            },
            deep: true
        },
    },
    data() {
        return {
            dialogTableVisible: false,
            selectedData: [],
            drawerCreate: false,
            loadingData: false,
            reloadList: false,
            procedure_name: ''
        };
    },
    methods: {
        ...mapActions({
            updateList: "licenseRequirementsAdd/index",
            setPagination: "licenseRequirementsAdd/setPagination",
            updateSort: "licenseRequirementsAdd/updateSort",
            updateFilter: "licenseRequirementsAdd/updateFilter",
            updateColumn: "licenseRequirementsAdd/updateColumn",
            updatePagination: "licenseRequirementsAdd/updatePagination",
            show: "licenseRequirementsAdd/show",
            empty: "licenseRequirementsAdd/empty",
            delete: "licenseRequirementsAdd/destroy",
            refreshData: "licenseRequirementsAdd/refreshData",
        }),
        fetchData() { 
            const query = {
                license_requirement_id: parseInt(this.$route.params.id),
                ...this.pagination,
            };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false;
                    this.$emit('get-name', res.procedure);
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        ShowData(data){
            if (data) {
                this.dialogTableVisible = true;
                this.selectedData = data.items;
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        drawerClosed(ref){
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
        },
        closeDrawer(drawer){
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
    }
};
</script>
<style>
.success-icon {
    font-size: 20px;
    color: green;
}
.danger-icon {
  font-size: 20px;
    color: red;  
}
</style>