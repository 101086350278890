<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.roles"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Uskuna turi" prop="equipment_type_id">
                            <select-equipment-type class="w-100" v-model="form.equipment_type_id" :id="form.equipment_type_id" :size="'medium'"></select-equipment-type>
                        </el-form-item>
                    </el-col> 
                    <el-col :span="8">
                        <el-form-item label="Soni (ta)">
                            <el-col :span="11">
                                <el-input-number  class="w-100"  v-model="form.until" :min="0" size="medium"></el-input-number>
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Seriya va raqami">
                            <el-input
                                placeholder="Seriya va raqami"
                                :disabled="true" size="medium">
                            </el-input>
                        </el-form-item>
                    </el-col>
                   
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.series_status" @change="handleChange(form.series_status, 'series_and_number')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Pasport">
                            <el-select class="w-100" v-model="form.passport" placeholder="Pasport" size="medium" :disabled="has_passport" clearable>
                                <el-option label="Ha" :value="true"></el-option>
                                <el-option label="Yo'q" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                   
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.passport_status" @change="handleChange(form.passport_status, 'passport')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Qo'llanmasi">
                            <el-select class="w-100" v-model="form.manual" placeholder="Qo'llanmasi" size="medium" :disabled="has_manual" clearable>
                                <el-option label="Ha" :value="true"></el-option>
                                <el-option label="Yo'q" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                 
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.manual_status" @change="handleChange(form.manual_status, 'manual')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Guvohnomasi">
                            <el-select class="w-100" v-model="form.document" placeholder="Guvohnomasi" :disabled="has_document" size="medium" clearable>
                               <el-option label="Ha" :value="true"></el-option>
                                <el-option label="Yo'q" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.document_status" @change="handleChange(form.document_status, 'document')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Guvohnomasi va raqami">
                            <el-input
                                placeholder="Guvohnomasi va raqami"
                                v-model="form.PasportID"
                                :disabled="true" size="medium">
                            </el-input>
                        </el-form-item>
                    </el-col>
                  
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.document_number_status" @change="handleChange(form.document_number_status, 'document_number')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Sertifikati">
                            <el-select class="w-100" v-model="form.certificate" placeholder="Sertifikati" :disabled="has_certificate" size="medium" clearable>
                               <el-option label="Ha" :value="true"></el-option>
                                <el-option label="Yo'q" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.certificate_status" @change="handleChange(form.certificate_status, 'certificate')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Sertifikati va raqami">
                            <el-input
                                placeholder="Sertifikati va raqami"
                                v-model="form.PasportID"
                                :disabled="true" size="medium">
                            </el-input>
                        </el-form-item>
                    </el-col>
                   
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.certificate_number_status" @change="handleChange(form.certificate_number_status, 'certificate_number')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Mulk huquqi">
                            <select-property-ownerships class="w-100" v-model="form.property_ownership_id" :id="form.property_ownership_id" :disabled="has_property_ownership" size="medium">
                            </select-property-ownerships>
                        </el-form-item>
                    </el-col>
                  
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.property_ownership_status" @change="handleChange(form.property_ownership_status, 'property_ownership')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Holati">
                            <select-conditions class="w-100" v-model="form.condition_id" :id="form.condition_id" :disabled="has_condition" size="medium"></select-conditions>
                        </el-form-item>
                    </el-col>
                   
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.condition_status" @change="handleChange(form.condition_status, 'condition')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>

                 <!-- Ishlab chiqarilgan sanasi -->
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Ishlab chiqarilgan sanasi">
                            <el-input
                                placeholder="Ishlab chiqarilgan sanasi"
                                :disabled="true" size="medium">
                            </el-input>
                        </el-form-item>
                    </el-col>
                  
                    <el-col :span="8">
                        <el-form-item label="Boshqarmoq">
                            <el-switch v-model="form.date_of_manufacture_status" @change="handleChange(form.date_of_manufacture_status, 'date_of_manufacture')"></el-switch>
                        </el-form-item>
                    </el-col>     
                </el-row>     
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import form from "../../../../utils/mixins/form";
import drawer from '../../../../utils/mixins/drawer';
    export default {
        mixins: [form, drawer],
        computed: {
            ...mapGetters({
                model: "licenseRequirementEquipments/model",
                rules: "licenseRequirementEquipments/rules",
                columns: "licenseRequirementEquipments/columns"
            })
        },
        data() {
            return {
                has_series: true,
                has_passport: true,
                has_manual: true,
                has_document: true,
                has_document_number: true,
                has_certificate: true,
                has_certificate_number: true,
                has_property_ownership: true,
                has_condition: true,
                has_date_of_manufacture: true
            }
        },
        methods: {
            ...mapActions({
                save: 'licenseRequirementEquipments/store'
            }),
            afterOpen(){
                this.form = JSON.parse(JSON.stringify(this.model));
            },
             submit(close = true) {
                this.form.license_requirement_id = parseInt(this.$route.params.id);
                let items = [];
                
                if (this.form.until) {
                    items.push({
                        name: 'equipment_type',
                        value_type: 'li_interval',
                        until: this.form.until
                    });
                }
                if (this.form.series_status) {
                    items.push({
                        name: 'series_and_number',
                        li_status: this.form.series_status
                    });
                }
                if (this.form.passport_status) {
                    items.push({
                        name: 'passport',
                        value_type: 'li_boolean',
                        li_type: this.form.passport,
                        li_status: this.form.passport_status
                    });
                }
                if (this.form.manual_status) {
                    items.push({
                        name: 'manual',
                        value_type: 'li_boolean',
                        li_type: this.form.manual,
                        li_status: this.form.manual_status
                    });
                }
                if (this.form.document_status){
                    items.push({
                        name: 'document',
                        value_type: 'li_boolean',
                        li_type: this.form.document,
                        li_status: this.form.document_status
                    });
                }
                if (this.form.document_number_status){
                    items.push({
                        name: 'document_number',
                        li_status: this.form.document_number_status
                    });
                }
                if (this.form.certificate_status){
                    items.push({
                        name: 'certificate',
                        value_type: 'li_boolean',
                        li_type: this.form.certificate,
                        li_status: this.form.certificate_status
                    });
                }
                if (this.form.certificate_number_status){
                    items.push({
                        name: 'certificate_number',
                        li_status: this.form.certificate_number_status
                    });
                }
                if (this.form.property_ownership_status){
                    items.push({
                        name: 'property_ownership',
                        value_type: 'li_select',
                        source_id: this.form.property_ownership_id,
                        li_status: this.form.property_ownership_status
                    });
                }
                if (this.form.condition_status){
                    items.push({
                        name: 'condition',
                        value_type: 'li_select',
                        source_id: this.form.condition_id,
                        li_status: this.form.condition_status
                    });
                }
                if (this.form.date_of_manufacture_status) {
                    items.push({
                        name: 'date_of_manufacture',
                        li_status: this.form.date_of_manufacture_status
                    });
                }
                this.form['items'] = items;

                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                              //   this.$alert(res);
                                this.parent().listChanged();
                                if (close) this.close();
										   if ([200,201].includes(res.status)) {
												return this.$notify({
													title: 'Успешно ',
													type: "success",
													// offset: 130,
													position: 'top-left',
													message: res.data.result.message
												});
											}
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
            handleChange(event, type_name){
                if (type_name == 'series_and_number') {
                    this.has_series = event ? false : true;
                }
                else if (type_name == 'passport'){
                    this.has_passport = event ? false : true;
                }
                else if (type_name == 'manual') {
                    this.has_manual = event ? false : true;
                }
                else if (type_name == 'document') {
                    this.has_document = event ? false : true;
                }
                else if (type_name == 'document_number') {
                    this.has_document_number = event ? false : true;
                }
                else if (type_name == 'certificate') {
                    this.has_certificate = event ? false : true;
                }
                 else if (type_name == 'certificate_number') {
                    this.has_certificate_number = event ? false : true;
                }
                else if (type_name == 'property_ownership') {
                    this.has_property_ownership = event ? false : true;
                }
                else if (type_name == 'condition') {
                    this.has_condition = event ? false : true;
                }
                else if (type_name == 'date_of_manufacture'){
                    this.has_date_of_manufacture = event ? false : true;
                }
            },
            afterLeave(){
                this.$store.commit('licenseRequirementEquipments/EMPTY_MODEL');
                this.has_series = true;
                this.has_passport = true;
                this.has_manual = true;
                this.has_document = true;
                this.has_document_number = true;
                this.has_certificate = true;
                this.has_certificate_number = true;
                this.has_property_ownership = true;
                this.has_condition = true;
                this.has_validity_period = true;
            }
        }
    }
  </script>